@layer components {
  .heading {
    border-bottom: var(--heading-border-bottom);
    color: var(--heading-color);
    line-height: var(--heading-line-height);
    margin: var(--heading-margin);
    padding: var(--heading-padding);
    text-transform: var(--heading-text-transform);
    text-align: var(--heading-text-align);
    transition: var(--heading-transition);

    &:not(.headingLevel1, .headingLevel2, .headingLevel3, .headingLevel4, .headingLevelBody, .headingLevelHeadline) {
      font-size: var(--heading-font-size);
    }

    &:not(.headingWithBodyFont) {
      font-family: var(--font-family-headline);
      font-weight: var(--heading-font-weight);
      letter-spacing: var(--heading-letter-spacing);
    }
  }

  .headingLevel1 {
    font-size: var(--font-size-2xl);
  }

  .headingLevel2 {
    font-size: var(--font-size-xl);
  }

  .headingLevel3 {
    font-size: var(--font-size-lg);
  }

  .headingLevel4 {
    font-size: var(--font-size-md);
  }

  .headingLevelBody {
    font-size: var(--font-size-body);
  }

  .headingLevelHeadline {
    font-size: var(--font-size-5xl);
  }

  .headingWithBodyFont {
    font-family: var(--font-family-body);
    font-weight: var(--heading-with-body-font-font-weight);
  }
}
