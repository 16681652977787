.listPropertyPageTheme {
  --background: transparent;
  --color: var(--color-gray-500);
  --accent-color: var(--color-red-400);
  --links-color: var(--color-blue-500);
  --links-hover: var(--color-blue-400);
  --button-width: 215px;
  --heading-color: var(--color-black);
  --heading-font-size: var(--font-size-2xl);
  --heading-line-height: 25px;
  --property-type-margin: 0 auto var(--space-8x) auto;
  --property-type-cta-margin: 0 auto var(--space-6x) auto;
  --value-prop-content-font-size: var(--font-size-body);
  --property-brand-link-color: var(--color-blue-600);
  --section-background: var(--list-property-page-background);
  --divider-display: none;

  & strong {
    color: var(--color-black);
  }

  @media (--screen-md) {
    --heading-font-size: var(--font-size-4xl);
    --heading-line-height: 35px;
  }
}

.container {
  text-align: center;
  --container-padding: var(--space-14x) var(--space-6x);
  --container-max-width: 382px;

  @media (--screen-md) {
    --container-max-width: 700px;
    --container-padding: var(--space-14x) 0;
  }
}

.propertyTypeSubTitle {
  margin-top: var(--space-5x);
  background: var(--color-green-600);
  color: var(--color-white);
  padding: var(--space-1x) var(--space-2x);
  font-weight: var(--font-weight-semibold);
  border-radius: var(--space-1x);
  font-size: var(--font-size-sm);
  display: inline-block;
}

.lightGrayIcon {
  background: var(--color-gray-100);
  color: black;
}
