.diamondMaxBase {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 42px 30px 0;

  @media (--screen-md) {
    margin: 50px var(--space-6x) 0;
  }
}

.diamondMaxDetails {
  display: flex;
  gap: 3px;
  padding: var(--space-3x) var(--space-5x);
  flex-direction: column;
  border-radius: var(--border-radius-4x);
  line-height: var(--space-6x);
  align-items: center;
  background-color: var(--color-white);
  color: var(--color-black);
  font-size: var(--font-size-root);
  position: relative;
  box-shadow: var(--shadow-200);
  max-width: 100%;
}

@media (--screen-md) {
  .diamondMaxDetails {
    width: auto;
    gap: 0;
    display: flex;
    height: 50px;
    flex-direction: row;
  }
}

.diamondMaxHighlightedText {
  font-weight: var(--font-weight-semibold);
}

.diamondMaxDetails::before {
  border-bottom: 29px solid var(--color-white);
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
  content: '';
  left: 0;
  right: 0;
  width: 0;
  height: 0;
  position: absolute;
  top: -28px;
  margin: auto;
}

.diamondMaxPipe {
  display: none;
  width: 2px;
  height: 14px;
  margin: 0 var(--space-4x);
  background: var(--color-gray-200);

  @media (--screen-md) {
    display: block;
  }
}

.diamondMaxPipeMobile {
  display: block;
}

.diamondMaxPriceLocation {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (--screen-md) {
    width: auto;
    margin: 0;
  }
}

.diamondMaxDetail {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
