.heading {
  --heading-margin: 0 0 var(--space-10x) 0;
}

.disclosure {
  background: var(--color-white);
  margin-bottom: var(--space-3x);
  border-radius: 3px;
  /* stylelint-disable color-function-notation */
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 10%);
}

.disclosureButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--space-4x);
  width: 100%;
  padding: var(--space-6x) var(--space-4x);
  font-weight: var(--font-weight-semibold);
  border-radius: var(--space-1x);
  text-align: left;
}

.disclosurePanel {
  width: 100%;
  text-align: left;
  padding: 0 var(--space-4x) var(--space-6x) var(--space-4x);
  color: var(--color-gray-500);
  font-size: var(--font-size-body);
}

.sectionBackground {
  background: var(--section-background);
}
